import { CrewUi, enumToZodLiteralUnion } from 'corso-types';

import { z } from 'zod';
import {
  ClaimReasonCategoryCode,
  ClaimType,
  MediaType,
  ResolutionMethod,
} from './constants';

export const {
  claimAddressSchema,
  shipmentCreateSchema,
  shipmentMethodSchema,
  claimLineItemCreateSchema,
} = CrewUi;

export const typeGuardFromSchema =
  <Output, Def extends z.ZodTypeDef, Input>(
    schema: z.ZodType<Output, Def, Input>,
  ) =>
  (data: unknown): data is Input =>
    schema.safeParse(data).success;

const errorMessage = z.object({
  message: z.string(),
});

export const isErrorMessage = (
  obj: unknown,
): obj is z.infer<typeof errorMessage> => errorMessage.safeParse(obj).success;

export function isApiError(
  body: unknown,
): body is z.infer<typeof errorMessage> {
  return errorMessage.safeParse(body).success;
}

export const mediaAssetSchema = z.object({
  src: z.string(),
  name: z.string(),
  type: z.nativeEnum(MediaType),
});

export const stringToJSONSchema = z.string().transform((str, ctx) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    ctx.addIssue({ code: 'custom', message: 'Invalid JSON' });
    return z.NEVER;
  }
});

export const crewOrderLineItemSchema = CrewUi.crewOrderLineItemSchema.extend({
  remainingReturnableQuantity: z.number(),
  unitPriceDisplay: z.string(),
});

const lineItemClaimReasonSchema = z.object({
  id: z.number(),
  name: z.string(),
  category: enumToZodLiteralUnion(ClaimReasonCategoryCode),
  detail: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .optional(),
});

export const exchangeVariantSchema = CrewUi.variantExchangeOptionsSchema;

export const draftLineItemClaimSchema = CrewUi.claimLineItemCreateSchema
  .omit({
    images: true,
    reason: true,
    customFields: true,
    requestedResolutionMethodEnum: true,
  })
  .extend({
    id: z.string(),
    claimType: z.nativeEnum(ClaimType),
    lineItem: crewOrderLineItemSchema,
    quantity: z.number(),
    variant: exchangeVariantSchema.optional(),
    unitPrice: z.number(),
    unitPriceDisplay: z.string(),
    unitTax: z.number(),
    unitListPrice: z.number().optional(),
    customFields: z.record(z.string()),
    grams: z.number(),
    assets: z.array(mediaAssetSchema).optional(),
    requestedResolutionMethodEnum: z
      .enum([
        ResolutionMethod.refund,
        ResolutionMethod.variantExchange,
        ResolutionMethod.giftCard,
        ResolutionMethod.warrantyReview,
      ])
      .optional(),
    reason: lineItemClaimReasonSchema.optional(),
  });

export const variantExchangeClaimSchema = draftLineItemClaimSchema
  .omit({ requestedResolutionMethodEnum: true })
  .extend({
    requestedResolutionMethodEnum: z.literal(ResolutionMethod.variantExchange),
    variant: CrewUi.variantExchangeOptionsSchema,
    reason: lineItemClaimReasonSchema,
    claimType: z.literal(ClaimType.return),
  });

export const refundClaimSchema = draftLineItemClaimSchema
  .omit({ requestedResolutionMethodEnum: true })
  .extend({
    requestedResolutionMethodEnum: z.literal(ResolutionMethod.refund),

    variant: z.never().optional(),
    reason: lineItemClaimReasonSchema,
    claimType: z.literal(ClaimType.return),
  });

export const giftCardClaimSchema = draftLineItemClaimSchema
  .omit({ requestedResolutionMethodEnum: true })
  .extend({
    requestedResolutionMethodEnum: z.literal(ResolutionMethod.giftCard),
    variant: z.never().optional(),
    reason: lineItemClaimReasonSchema,
    claimType: z.literal(ClaimType.return),
  });

const warrantyClaimSchema = draftLineItemClaimSchema
  .omit({ requestedResolutionMethodEnum: true })
  .extend({
    requestedResolutionMethodEnum: z.literal(ResolutionMethod.warrantyReview),
    variant: z.never().optional(),
    reason: lineItemClaimReasonSchema,
    claimType: z.literal(ClaimType.warranty),
  });

export const lineItemClaimSchema = z.discriminatedUnion(
  'requestedResolutionMethodEnum',
  [
    variantExchangeClaimSchema,
    refundClaimSchema,
    giftCardClaimSchema,
    warrantyClaimSchema,
  ],
);

export const crewOrderSchema = CrewUi.orderSchema.extend({
  lineItems: z.array(crewOrderLineItemSchema),
});

export const crewStateSchema = z.object({
  settings: CrewUi.settingsSchema,
  claim: CrewUi.claimSchema.nullable(),
  draftClaim: draftLineItemClaimSchema.nullable(),
  lineItemClaims: z.array(lineItemClaimSchema),
  order: crewOrderSchema.nullable(),
  address: CrewUi.claimAddressSchema.nullable(),
  toCustomerRate: CrewUi.shipmentRateSchema.nullable(),
  returnMethod: CrewUi.shipmentMethodSchema.nullable(),
});

const storeSalesChannelSchema = z.object({
  id: z.number(),
  name: z.string(),
});

export const draftRegistrationSchema = z.object({
  email: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  storeSalesChannel: storeSalesChannelSchema,
  estimatedPurchaseDate: z.string(),
  address: z.nullable(CrewUi.claimAddressSchema),
  proofOfPurchaseAssets: z.array(mediaAssetSchema).nullable(),
  orderNumber: z.string().optional(),
  referrer: z.string().optional(),
  customFields: z.record(z.string()),
});

export const registrationStateSchema = z.object({
  settings: CrewUi.settingsSchema,
  draftRegistration: draftRegistrationSchema.nullable(),
});
