import { XMarkIcon } from '@heroicons/react/20/solid';
import { PhotoIcon } from '@heroicons/react/24/solid';
import { ChangeEvent, KeyboardEvent, useId, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import Button from '~/components/button';
import {
  Modal,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from '~/components/modal';
import Spinner from '~/components/spinner';
import { MediaType } from '~/utils/constants';
import { determineFileType } from '~/utils/file';
import Field from './field';

type PreviewProps = {
  asset: {
    name: string;
    src: string;
    type: MediaType;
  };
  onRemove(): void;
};

function FilePreview({ asset, onRemove }: PreviewProps) {
  return (
    <figure className="relative aspect-square w-full min-w-[80px] rounded-lg border border-gray-300">
      {asset.type === 'video' ?
        <video
          controls
          className="h-full w-full rounded-t-lg object-cover"
          src={asset.src}
          muted
        />
      : <img
          className="h-full w-full rounded-t-lg object-cover"
          src={
            asset.type === 'image' ?
              asset.src
            : 'https://cdn.corso.com/img/image-not-available.jpeg'
          }
          alt="Preview"
        />
      }
      <figcaption
        title={asset.name}
        className="truncate rounded-b-lg bg-black bg-opacity-70 px-2 py-1 text-white"
      >
        {asset.name}
      </figcaption>
      <Button
        variant="text"
        className="absolute right-1 top-1 rounded-full bg-black bg-opacity-70 p-2 text-white hover:text-white"
        onClick={onRemove}
      >
        <XMarkIcon className="h-5 w-5" title="Remove File" />
        <span className="sr-only">Remove File</span>
      </Button>
    </figure>
  );
}

type ButtonProps = {
  name: string;
  loading?: boolean;
  required?: boolean;
  files: (PreviewProps['asset'] & { file?: File })[];
  onChange(files: FileList): void;
  accept?: HTMLInputElement['accept'];
  ariaDescribedby?: string;
};

function FileUpload({
  name,
  files,
  loading,
  required,
  onChange,
  accept = '*',
  ariaDescribedby,
}: ButtonProps) {
  const ref = useRef<HTMLInputElement>(null);
  const onClick = () => ref.current?.click();
  const onKeyUp = ({ key }: KeyboardEvent) => {
    if (key === 'Enter' || key === ' ') {
      ref.current?.click();
    }
  };
  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      onChange(event.target.files);
    }
  };

  if (ref.current) {
    const dt = new DataTransfer();
    files.forEach(({ file }) => {
      if (file) dt.items.add(file);
    });
    ref.current.files = dt.files;
  }

  return (
    <>
      <Button
        className="w-full sm:max-w-max"
        disabled={loading}
        variant="outlined"
        onClick={onClick}
        onKeyUp={onKeyUp}
        aria-describedby={ariaDescribedby}
      >
        {loading ?
          <Spinner className="h-5 w-5" />
        : <PhotoIcon className="h-6 w-6" title="Upload Files" />}
        <span className="ml-3">Upload</span>
        {required && <span className="sr-only">required</span>}
        <input
          multiple
          hidden
          name="files"
          data-testid="file-input"
          ref={ref}
          type="file"
          accept={accept}
          onChange={onInputChange}
        />
      </Button>

      {files
        .filter(({ file }) => !file)
        .map((asset) => (
          <input
            key={asset.name}
            aria-hidden
            type="hidden"
            name={name}
            defaultValue={JSON.stringify(asset)}
          />
        ))}
    </>
  );
}

type AcceptType =
  | 'image/jpeg'
  | 'image/png'
  | 'image/webp'
  | 'video/mp4'
  | 'video/webm'
  | 'application/pdf';

type Props = {
  className?: string;
  name: string;
  requiredCount?: number;
  value?: PreviewProps['asset'][];
  description?: string;
  accept?: AcceptType[];
};
export default function FileUploader({
  className,
  name: inputName,
  requiredCount = 0,
  value = [],
  description,
  accept = [
    'image/jpeg',
    'image/png',
    'image/webp',
    'video/mp4',
    'video/webm',
    'application/pdf',
  ],
}: Props) {
  const [assets, setAssets] = useState<NonNullable<Props['value']>>(value);
  const [assetToRemove, setAssetToRemove] = useState('');
  const descriptionId = useId();
  const acceptId = useId();

  const onUpload = (fileList: FileList) => {
    const files = Array.from(fileList);

    const newAssets = [
      ...assets,
      ...files.map((file) => ({
        file,
        name: file.name,
        src: URL.createObjectURL(file),
        type: determineFileType(file),
      })),
    ];
    setAssets(newAssets);
  };

  const openConfirm = (fileName: string) => () => setAssetToRemove(fileName);

  const closeConfirm = () => setAssetToRemove('');

  const removeAsset = () => {
    setAssets(assets.filter(({ name }) => name !== assetToRemove));
    closeConfirm();
  };

  return (
    <div role="group" className={twMerge('flex flex-col gap-2', className)}>
      <FileUpload
        name={inputName}
        onChange={onUpload}
        required={requiredCount > 0}
        files={assets}
        accept={accept.join(',')}
        ariaDescribedby={[descriptionId, acceptId].join(' ')}
      />

      {(requiredCount > 0 || description) && (
        <Field.HelpText id={acceptId} className="text-gray-500">
          {requiredCount > 0 && (
            <span>
              A minimum of {requiredCount}{' '}
              {requiredCount === 1 ? 'file' : 'files'} required.
            </span>
          )}
          {description && <span>{description}</span>}
        </Field.HelpText>
      )}

      {assets.length > 0 && (
        <ul className="grid grid-cols-2 gap-4 sm:grid-cols-4">
          {assets.map((asset) => (
            <li key={asset.name}>
              <FilePreview asset={asset} onRemove={openConfirm(asset.name)} />
            </li>
          ))}
        </ul>
      )}

      <Modal open={!!assetToRemove} onClose={closeConfirm}>
        <ModalHeader>
          <ModalTitle>Do you want to remove this file?</ModalTitle>
        </ModalHeader>
        <p className="text-sm">
          Removing the file will delete this file from your request.
        </p>
        <ModalFooter>
          <Button variant="outlined" onClick={closeConfirm}>
            Keep File
          </Button>
          <Button onClick={removeAsset}>Remove File</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
