import { useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import { getAddressFields } from '~/utils/address';
import { Address, OrderAddress } from '~/utils/types';
import Input from './form-controls/input';

export default function AddressFields({
  address,
  errors,
  className,
  pick,
}: {
  address?: Address | OrderAddress | null;
  errors?: Partial<Record<keyof Address, string>>;
  className?: string;
  pick?: (keyof Address)[];
}) {
  const fields = useMemo(() => {
    const entries = Object.entries(getAddressFields(address));

    return pick ? entries.filter(([key]) => pick.includes(key)) : entries;
  }, [address, pick]);

  return (
    <div
      className={twMerge('grid grid-cols-1 gap-4 sm:grid-cols-2', className)}
    >
      {fields.map(([key, field]) => (
        <Input
          type={field.type}
          required={field.required}
          id={key}
          key={key}
          className={twMerge(field.name === 'line1' && 'sm:col-span-2')}
          name={field.name}
          label={field.label}
          description={field.placeholder}
          autoComplete={field.autoComplete}
          defaultValue={field.defaultValue}
          pattern={field.pattern}
          validationMessages={{
            valueMissing:
              errors?.[field.name] ? errors[field.name] : field.invalidMessage,
          }}
          title={field.invalidMessage}
        />
      ))}
    </div>
  );
}
