import * as Sentry from '@sentry/react';
import env from '~/env';

type ScopeContext = Exclude<
  Parameters<typeof Sentry.captureMessage>[1],
  | Sentry.SeverityLevel
  | Sentry.Scope
  | ((scope: Sentry.Scope) => Sentry.Scope)
  | undefined
>;
type ExtraData<Severity extends Sentry.SeverityLevel> = Pick<
  ScopeContext,
  'extra' | 'tags'
> & {
  severity?: Severity;
};

export function logError(
  error: unknown,
  extraData: ExtraData<
    Exclude<Sentry.SeverityLevel, 'log' | 'info' | 'debug'>
  > = {},
) {
  Sentry.captureException(error, extraData);
}
export function logMessage(
  message: string,
  extraData?: ExtraData<Exclude<Sentry.SeverityLevel, 'fatal' | 'error'>>,
) {
  Sentry.captureMessage(message, extraData);
}

type IdentifyUserParams = {
  email: string;
  storeName: string;
  storeId: number;
  orderNo: string;
  orderIdFromPlatform: string;
  appType: 'GSP' | 'CREW';
};

export function identifyUser(params: IdentifyUserParams) {
  try {
    if (env.VITE_NODE_ENV === 'prod') {
      const {
        email,
        storeId,
        storeName,
        appType,
        orderIdFromPlatform,
        orderNo,
      } = params;

      Sentry.setUser({
        email,
        username: email,
      });

      const isEmbedded = window.parent !== window ? 'true' : 'false';

      Sentry.setTag('storeName', storeName);
      Sentry.setTag('storeId', storeId.toString());
      Sentry.setTag('appType', appType);
      Sentry.setTag('orderIdFromPlatform', orderIdFromPlatform);
      Sentry.setTag('orderNo', orderNo);
      Sentry.setTag(`isEmbedded`, isEmbedded);
    }
  } catch (error) {
    logError(error);
  }
}
