// * generally want to limit the imports of corso-types but this component is built specifically for a type from corso-types
import { CrewUi } from 'corso-types';
import Input from './form-controls/input';
import { Select, SelectItem } from './form-controls/select';

type CustomFieldType =
  CrewUi.CollectCustomFieldEvent['params']['customFields'][number];

const lookupInputType = (valueType: CustomFieldType['valueType']) => {
  switch (valueType) {
    case 'Number':
      return 'number';
    case 'Date':
      return 'date';
    default:
      return 'text';
  }
};

export default function CustomField({
  field,
  defaultValue,
  className,
}: {
  field: CustomFieldType;
  defaultValue?: string;
  className?: string;
}) {
  return field.valueType === 'Select' ?
      <Select
        key={field.id}
        id={`${field.id}`}
        className={className}
        // default to true
        required={field.required ?? true}
        description={field.description}
        name={`${field.id}`}
        label={field.displayName}
      >
        {field.options.map((option) => (
          <SelectItem key={option} value={option}>
            {option}
          </SelectItem>
        ))}
      </Select>
    : <Input
        key={field.id}
        id={`${field.id}`}
        name={`${field.id}`}
        className={className}
        description={field.description}
        label={field.displayName}
        type={lookupInputType(field.valueType)}
        required={field.required}
        defaultValue={defaultValue}
      />;
}
