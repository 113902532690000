import { z } from 'zod';
import { draftRegistrationSchema } from './schemas';

type DraftRegistration = z.infer<typeof draftRegistrationSchema>;

export const draftRegistrationToCreate = (
  draft: DraftRegistration,
  storeProductVariants: { id: number; quantity: number }[],
) => ({
  firstName: draft.firstName,
  lastName: draft.lastName,
  storeSalesChannelId: draft.storeSalesChannel.id,
  estimatedPurchaseDate: draft.estimatedPurchaseDate,
  email: draft.email,
  storeProductVariants,
  address: draft.address ?? undefined,
  proofOfPurchaseFilenames:
    draft.proofOfPurchaseAssets?.map((asset) => asset.name) ?? [],
  referrer: draft.referrer,
  orderNumber: draft.orderNumber,
  customFields: Object.entries(draft.customFields).map(([id, value]) => ({
    id: Number(id),
    value,
  })),
});
