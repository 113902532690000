import { validateAddressData } from '~/utils/address';
import {
  createActionResultHook,
  error,
  gspAction,
  json,
  redirect,
} from '~/utils/routing';
import { ActionResult, Address } from '~/utils/types';

export default gspAction(({ context, formData }) => {
  if (!context.order) {
    return error(new Error('Context Error: Order not found'));
  }

  const result = validateAddressData(formData);

  if (!result.ok) {
    return json<ActionResult<keyof Address>>({
      ok: false,
      form: result.errors,
    });
  }

  context.order.shippingAddress = result.data;

  return redirect('../../review');
});

export const useEditAddressActionResult =
  createActionResultHook<ActionResult<keyof Address>>();
